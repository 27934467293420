<template>
  <div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('assets/media/bg/bg-3.jpg')"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-10" v-if="isROKS">
          <div>
            <LogoRoks />
          </div>
        </div>
        <!--end::Login Header-->
        <!--begin::Login Sign in form-->
        <div class="login-signin">
          <div class="mb-20">
            <h3 class="display-3">Авторизация</h3>
            <div class="text-muted font-weight-bold">Введите данные от аккаунта:</div>
          </div>
          <form
            class="form"
            id="kt_login_signin_form"
            :class="{ '--disabled': loading }"
            @submit.prevent="onSubmit"
          >
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                :class="{ 'border-danger': errorText !== '' }"
                type="text"
                placeholder="Логин"
                name="username"
                v-model="username"
                autocomplete="off"
              />
            </div>
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                :class="{ 'border-danger': errorText !== '' }"
                type="password"
                placeholder="Пароль"
                v-model="password"
                name="password"
              />
            </div>
            <div v-if="errorText !== ''" class="text-danger mt-n8 mb-8">
              {{ errorText }}
            </div>
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <!-- <router-link :to="{ name: 'restoration' }" class="text-muted text-hover-primary"
                >Забыли пароль?</router-link
              > -->
            </div>
            <button
              class="_login-btn btn font-weight-bold px-9 py-4 my-3 mx-4"
              :class="{ '--disabled': username === '' || password === '', '--roks': isROKS }"
            >
              Войти
            </button>

            <router-link
              v-if="false"
              :to="{ name: 'loginToken' }"
              tag="button"
              class="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <span class="svg-icon svg-icon-danger"
                ><!--begin::Svg Icon | path:C:\wamp64\www\keenthemes\legacy\metronic\theme\html\demo1\dist/../src/media/svg/icons\Devices\Usb-storage.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M11.5,17.4497475 L6.55025253,12.5 C6.15972824,12.1094757 6.15972824,11.4763107 6.55025253,11.0857864 L13.6213203,4.01471863 C14.4023689,3.23367004 15.6686989,3.23367004 16.4497475,4.01471863 L19.9852814,7.55025253 C20.76633,8.33130112 20.76633,9.59763107 19.9852814,10.3786797 L12.9142136,17.4497475 C12.5236893,17.8402718 11.8905243,17.8402718 11.5,17.4497475 Z"
                      fill="#000000"
                    />
                    <path
                      d="M6.90380592,19.9246212 L4.0753788,17.0961941 C3.88011665,16.9009319 3.88011665,16.5843494 4.0753788,16.3890873 L6.55025253,13.9142136 L10.0857864,17.4497475 L7.6109127,19.9246212 C7.41565056,20.1198833 7.09906807,20.1198833 6.90380592,19.9246212 Z"
                      fill="#000000"
                      opacity="0.3"
                    />
                  </g></svg
                ><!--end::Svg Icon--></span
              >
              Вход по ЭЦП
            </router-link>
          </form>
          <span class="d-flex align-center" v-if="loading">
            <div class="spinner-border text-secondary spinner-border-sm mr-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            Ожидание ответа
          </span>
        </div>
        <!--end::Login Sign in form-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getUrl, isUrlExists, removeUrl } from "@/core/utils/returnUrlController";
import api from "@/core/api";
import { useRouter } from "vue-router";

import { auth } from "@/core/services/auth";
import LogoRoks from "@/assets/icons/roks-logo-main-rgb-vertical-tag.svg";

export default {
  components: { LogoRoks },
  data() {
    return {
      isROKS: window.location.href.indexOf("roksperm.ru") !== -1,
    };
  },
  watch: {
    username() {
      this.errorText = "";
    },
    password() {
      this.errorText = "";
    },
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const loading = ref(false);
    const errorText = ref("");

    const router = useRouter();

    const redirectToBack = (id) => {
      const returnUrl = getUrl(id);
      removeUrl();
      window.open(returnUrl, "_self");
    };

    const getToken = (id) => {
      api.getToken(id).then((resp) => {
        auth.setToken(resp.data.access_token);
        router.push({ name: "admin" });
      });
    };

    const onSubmit = async () => {
      try {
        const formData = new FormData();
        formData.append("username", username.value.trim());
        loading.value = true;

        // console.log(username.value);
        // if( /^\+?7\d{10}$/.test(username.value) ){
        //   password.value = Math.random();
        // }

        formData.append("password", password.value.trim());
        const {
          data: { id },
        } = await api.getAccessToken(formData);

        if (id === "sms_code") {
          return router.push({ name: "sms" });
        }

        if (isUrlExists()) {
          redirectToBack(id);
        } else {
          getToken(id);
        }
      } catch (e) {
        if (e.response.data.detail === "Incorrect email or password") {
          errorText.value = "Введен неверный логин или пароль";
        } else {
          alert("Ошибка авторизации");
        }
      }
      loading.value = false;
    };

    if (auth.loggedIn()) {
      api.getUser().then((resp) => {
        if (isUrlExists()) {
          if (!confirm(`Хотите продолжить как пользователь ${resp.data.login}?`)) {
            auth.logOut();
            return;
          }

          api.getTemporaryId().then((resp) => {
            redirectToBack(resp.data.id);
          });
        } else {
          router.push({ name: "admin" });
        }
      });
    }

    return {
      loading,
      username,
      password,
      errorText,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss" src="./Login.scss"></style>
